<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("groupSubjects.title") }}
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="filter.educationYear"
                        :options="educationYears"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        v-model="filter.level"
                        :options="levels"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-lg-4">
                  <b-form-group>
                    <template v-slot:label>
                      Класс
                    </template>
                    <b-form-select
                        v-model="filter.groupId"
                        :options="groups"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary">{{
                  $t("common.filter")
                }}
              </b-button>
            </b-form>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">
                  {{ $t("groupSubjects.list.group") }}
                </th>
                <th class="text-left">
                  {{ $t("groupSubjects.list.subject") }}
                </th>
<!--                <th class="text-left">
                  {{ $t("groupSubjects.list.name") }}
                </th>-->
                <th class="text-left">
                  {{ $t("groupSubjects.list.teacher") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in subjects" :key="item.id">
                <td>{{ item.groupName }}</td>
                <td>{{ item.subjectName }} {{ item.subgroupName }}</td>
<!--                <td>{{ item.name }}</td>-->
                <td>{{ item.teacherName }}</td>
                <td>
                  <b-button
                      type="submit"
                      @click="openMarks(item)"
                      variant="primary"
                  >
                    {{ $t("marks.title") }}
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "schoolSubjects",
  components: {
    KTPortlet
  },
  data() {
    return {
      subjects: null,
      filter:{
        educationYear: DictionariesService.currentYear(),
        level: 1
      },
      levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      educationYears: DictionariesService.educationYearOptions(),
      shifts: [{text: "Первая", value: 1}, {text: "Вторая", value: 2}, { text: "Третья", value: 3 }],
      groups: [],
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      
      this.form.groupId = this.$route.params.id;      
    },
    reloadData() {
      ApiService.querySecured("groupSubjects/getForSchool", {
      }).then(({data}) => {
        this.subjects = data.list;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    openMarks(groupSubject) {
      if(groupSubject.groupSubjectSubGroupId != null){
        this.$router.push({name: "marks", params: {id: "sub-" + groupSubject.groupSubjectSubGroupId}});
      }else{
        this.$router.push({name: "marks", params: {id: groupSubject.groupSubjectId}});
      }
    }
  }
};
</script>
